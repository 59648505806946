import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<li class=\"tutorials-filters-areas-competences__item-checkbox\">\n  <PixCheckbox @id={{@item.id}} {{on \"input\" (fn @handleFilterChange @type @item.id)}} @checked={{this.isChecked}}>\n    {{@item.name}}\n  </PixCheckbox>\n</li>", {"contents":"<li class=\"tutorials-filters-areas-competences__item-checkbox\">\n  <PixCheckbox @id={{@item.id}} {{on \"input\" (fn @handleFilterChange @type @item.id)}} @checked={{this.isChecked}}>\n    {{@item.name}}\n  </PixCheckbox>\n</li>","moduleName":"mon-pix/components/user-tutorials/filters/item-checkbox.hbs","parseOptions":{"srcName":"mon-pix/components/user-tutorials/filters/item-checkbox.hbs"}});
import Component from '@glimmer/component';

export default class ItemCheckbox extends Component {
  constructor() {
    super(...arguments);

    if (!this.args.type) {
      throw new Error('ERROR in UserTutorials::Filters::ItemCheckbox component, you must provide @type params');
    }
  }

  get isChecked() {
    return this.args.currentFilters[this.args.type].includes(this.args.item.id);
  }
}
