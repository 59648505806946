import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"ordo-proposals\">\n    <DragSortList\n        class=\"proposal-draglist\"\n        @items = {{this.items}}\n        @isHorizontal = true\n        @dragEndAction = {{action \"dragEnd\"}}\n        as |item|\n    >\n        <div class=\"proposal-draglist-items\">{{{item}}}</div>\n    </DragSortList>\n    <p>{{t \"pages.ordo-proposal.title\"}}</p>\n    <DragSortList\n        class=\"proposal-draglist\"\n        @items = {{this.response}}\n        @isHorizontal = true\n        @dragEndAction = {{action \"dragEnd\"}}\n        as |item|\n    >\n        <div class=\"ordo-response\">{{{item}}}</div>\n    </DragSortList>\n</div>\n", {"contents":"<div class=\"ordo-proposals\">\n    <DragSortList\n        class=\"proposal-draglist\"\n        @items = {{this.items}}\n        @isHorizontal = true\n        @dragEndAction = {{action \"dragEnd\"}}\n        as |item|\n    >\n        <div class=\"proposal-draglist-items\">{{{item}}}</div>\n    </DragSortList>\n    <p>{{t \"pages.ordo-proposal.title\"}}</p>\n    <DragSortList\n        class=\"proposal-draglist\"\n        @items = {{this.response}}\n        @isHorizontal = true\n        @dragEndAction = {{action \"dragEnd\"}}\n        as |item|\n    >\n        <div class=\"ordo-response\">{{{item}}}</div>\n    </DragSortList>\n</div>\n","moduleName":"mon-pix/components/ordo-proposals.hbs","parseOptions":{"srcName":"mon-pix/components/ordo-proposals.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import proposalsAsArray from 'mon-pix/utils/proposals-as-array';
import { A } from '@ember/array';

export default class OrdoProposals extends Component { 
  items = A(this.ordoItems);
  response = A([]);
  responseIndex = A([]);

  get ordoItems() {
    const ordoItems = proposalsAsArray(this.args.proposals);
    const ordoItemsFormat = ordoItems.map((itemNoFormat) => itemNoFormat.replace('<br>', ''));
    return ordoItemsFormat;
  }

  @action
  dragEnd({ sourceList, sourceIndex, targetList, targetIndex }) {
    if (sourceList === targetList && sourceIndex === targetIndex) return;
  
    const regexp = /<span>.+?<\/span>/g;
    const rawItem = sourceList[sourceIndex];
    let item = this.ordoItems[this.ordoItems.indexOf(sourceList[sourceIndex])];
    if(item == undefined){
      item = rawItem.match(regexp)[0];
    }

    sourceList.removeAt(sourceIndex);
    if(targetList === this.response) {
      targetList.insertAt(targetIndex, item);
      targetList.forEach((element, index) => {
        const currentResponse = element.match(regexp)[0];
        let currentItem;
        if(index != targetList.length - 1){
          currentItem = '<div class="proposal-draglist-items">' + currentResponse + '</div><div class="arrow"></div>';
        }else {
          currentItem = '<div class="proposal-draglist-items">' + currentResponse + '</div>';
        }
        targetList.removeAt(index);
        targetList.insertAt(index, currentItem);
      });
    }else {
      targetList.insertAt(targetIndex, item);
    }

    this.responseIndex.clear();
    this.response.forEach((element) => {
      const initialElement = element.match(regexp)[0];
      const initialItemIndex = this.ordoItems.indexOf(initialElement) + 1;
      this.responseIndex.push(initialItemIndex);
    });
    this.args.answerChanged(this.responseIndex, this.items.length);
  }
}
