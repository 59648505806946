import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable builtin-component-arguments }}\n{{#each this.labeledRadios as |labeledRadio index|}}\n  <p class=\"proposal-paragraph qcu-proposals\">\n    <PixRadioButton\n      id=\"radio_{{inc index}}\"\n      name=\"radio\"\n      @label={{this.label}}\n      @value={{inc index}}\n      @isDisabled={{@isAnswerFieldDisabled}}\n      checked={{get labeledRadio 1}}\n      {{on \"click\" (fn this.radioClicked index)}}\n      data-value=\"{{inc index}}\"\n      class=\"qcu-proposals__radio\"\n      data-test=\"challenge-response-proposal-selector\"\n    />\n\n    <label for=\"radio_{{inc index}}\" class=\"qcu-proposals__label\">\n      <MarkdownToHtmlUnsafe @class=\"qcu-panel__text proposal-text\" @markdown={{get labeledRadio 0}} />\n    </label>\n  </p>\n{{/each}}\n", {"contents":"{{! template-lint-disable builtin-component-arguments }}\n{{#each this.labeledRadios as |labeledRadio index|}}\n  <p class=\"proposal-paragraph qcu-proposals\">\n    <PixRadioButton\n      id=\"radio_{{inc index}}\"\n      name=\"radio\"\n      @label={{this.label}}\n      @value={{inc index}}\n      @isDisabled={{@isAnswerFieldDisabled}}\n      checked={{get labeledRadio 1}}\n      {{on \"click\" (fn this.radioClicked index)}}\n      data-value=\"{{inc index}}\"\n      class=\"qcu-proposals__radio\"\n      data-test=\"challenge-response-proposal-selector\"\n    />\n\n    <label for=\"radio_{{inc index}}\" class=\"qcu-proposals__label\">\n      <MarkdownToHtmlUnsafe @class=\"qcu-panel__text proposal-text\" @markdown={{get labeledRadio 0}} />\n    </label>\n  </p>\n{{/each}}\n","moduleName":"mon-pix/components/qcu-proposals.hbs","parseOptions":{"srcName":"mon-pix/components/qcu-proposals.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import labeledCheckboxes from 'mon-pix/utils/labeled-checkboxes';
import proposalsAsArray from 'mon-pix/utils/proposals-as-array';
import valueAsArrayOfBoolean from 'mon-pix/utils/value-as-array-of-boolean';

export default class QcuProposals extends Component {
  get labeledRadios() {
    const arrayOfProposals = proposalsAsArray(this.args.proposals);
    return labeledCheckboxes(arrayOfProposals, valueAsArrayOfBoolean(this.args.answerValue));
  }

  @action
  radioClicked() {
    this.args.answerChanged();
  }
}
