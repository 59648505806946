import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isEnabled}}\n  <PixBanner @type={{this.bannerType}}>\n    {{text-with-multiple-lang this.bannerContent}}\n  </PixBanner>\n{{/if}}", {"contents":"{{#if this.isEnabled}}\n  <PixBanner @type={{this.bannerType}}>\n    {{text-with-multiple-lang this.bannerContent}}\n  </PixBanner>\n{{/if}}","moduleName":"mon-pix/components/communication-banner.hbs","parseOptions":{"srcName":"mon-pix/components/communication-banner.hbs"}});
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import isEmpty from 'lodash/isEmpty';
import ENV from 'mon-pix/config/environment';

export default class CommunicationBanner extends Component {
  bannerType = ENV.APP.BANNER_TYPE;

  _rawBannerContent = ENV.APP.BANNER_CONTENT;

  get isEnabled() {
    return !isEmpty(this._rawBannerContent) && !isEmpty(this.bannerType);
  }

  get bannerContent() {
    return htmlSafe(this._rawBannerContent);
  }
}
