import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{did-update this.resetLevelUp}}>\n  {{#unless this.closeLevelup}}\n    <div class=\"levelup\">\n      <img src=\"/images/levelup.svg\" alt=\"\" role=\"none\" />\n      <div class=\"levelup__icon-card-level\">{{@level}}</div>\n      <div class=\"levelup__competence\">\n        <div class=\"levelup-competence__level\">{{t \"pages.levelup-notif.obtained-level\" level=@level}}</div>\n        <div class=\"levelup-competence__name\">{{@competenceName}}</div>\n      </div>\n      <button\n        role=\"button\"\n        class=\"levelup__close\"\n        aria-labelledby=\"button-label\"\n        {{on \"click\" this.close}}\n        type=\"button\"\n      >\n        <span id=\"button-label\" hidden>{{t \"common.actions.close\"}}</span>\n        <FaIcon @icon=\"xmark\" role=\"presentation\" />\n      </button>\n    </div>\n  {{/unless}}\n</div>", {"contents":"<div {{did-update this.resetLevelUp}}>\n  {{#unless this.closeLevelup}}\n    <div class=\"levelup\">\n      <img src=\"/images/levelup.svg\" alt=\"\" role=\"none\" />\n      <div class=\"levelup__icon-card-level\">{{@level}}</div>\n      <div class=\"levelup__competence\">\n        <div class=\"levelup-competence__level\">{{t \"pages.levelup-notif.obtained-level\" level=@level}}</div>\n        <div class=\"levelup-competence__name\">{{@competenceName}}</div>\n      </div>\n      <button\n        role=\"button\"\n        class=\"levelup__close\"\n        aria-labelledby=\"button-label\"\n        {{on \"click\" this.close}}\n        type=\"button\"\n      >\n        <span id=\"button-label\" hidden>{{t \"common.actions.close\"}}</span>\n        <FaIcon @icon=\"xmark\" role=\"presentation\" />\n      </button>\n    </div>\n  {{/unless}}\n</div>","moduleName":"mon-pix/components/levelup-notif.hbs","parseOptions":{"srcName":"mon-pix/components/levelup-notif.hbs"}});
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class LevelupNotif extends Component {
  @tracked closeLevelup = false;

  resetLevelUp() {
    this.closeLevelup = false;
  }

  @action
  close() {
    this.closeLevelup = true;
  }
}
