import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"user-certifications-detail-competences-list\">\n  <h2>{{t \"pages.certificate.competences.title\"}}\n    <span>{{t \"pages.certificate.competences.subtitle\" maxReachableLevel=this.maxReachableLevel htmlSafe=true}}</span></h2>\n\n  {{#each this.sortedAreas as |area|}}\n    <UserCertificationsDetailCompetence @area={{area}} />\n  {{/each}}\n\n</div>", {"contents":"<div class=\"user-certifications-detail-competences-list\">\n  <h2>{{t \"pages.certificate.competences.title\"}}\n    <span>{{t \"pages.certificate.competences.subtitle\" maxReachableLevel=this.maxReachableLevel htmlSafe=true}}</span></h2>\n\n  {{#each this.sortedAreas as |area|}}\n    <UserCertificationsDetailCompetence @area={{area}} />\n  {{/each}}\n\n</div>","moduleName":"mon-pix/components/user-certifications-detail-competences-list.hbs","parseOptions":{"srcName":"mon-pix/components/user-certifications-detail-competences-list.hbs"}});
import Component from '@glimmer/component';

export default class UserCertificationsDetailCompetencesList extends Component {
  get sortedAreas() {
    return this.args.resultCompetenceTree.get('areas');
  }

  get maxReachableLevel() {
    return this.args.maxReachableLevelOnCertificationDate;
  }
}
