import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-action no-curly-component-invocation no-invalid-interactive }}\n<div class=\"pix-toggle-deprecated\">\n  <span\n    class=\"{{this.firstButtonClass}}\"\n    onToggle={{action \"onToggle\" \"firstButtonClass\" on=\"click\"}}\n  >{{this.valueFirstLabel}}</span>\n  <span\n    class=\"{{this.secondButtonClass}}\"\n    onToggle={{action \"onToggle\" \"secondButtonClass\" on=\"click\"}}\n  >{{this.valueSecondLabel}}</span>\n</div>\n{{yield}}", {"contents":"{{! template-lint-disable no-action no-curly-component-invocation no-invalid-interactive }}\n<div class=\"pix-toggle-deprecated\">\n  <span\n    class=\"{{this.firstButtonClass}}\"\n    onToggle={{action \"onToggle\" \"firstButtonClass\" on=\"click\"}}\n  >{{this.valueFirstLabel}}</span>\n  <span\n    class=\"{{this.secondButtonClass}}\"\n    onToggle={{action \"onToggle\" \"secondButtonClass\" on=\"click\"}}\n  >{{this.valueSecondLabel}}</span>\n</div>\n{{yield}}","moduleName":"mon-pix/components/pix-toggle-deprecated.hbs","parseOptions":{"srcName":"mon-pix/components/pix-toggle-deprecated.hbs"}});
/* eslint ember/no-actions-hash: 0 */
/* eslint ember/no-classic-classes: 0 */
/* eslint ember/no-classic-components: 0 */
/* eslint ember/require-tagless-components: 0 */

import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  valueFirstLabel: '',
  valueSecondLabel: '',
  isFirstOn: true,

  firstButtonClass: computed('isFirstOn', function () {
    return this.isFirstOn ? 'pix-toggle-deprecated__on' : 'pix-toggle-deprecated__off';
  }),

  secondButtonClass: computed('isFirstOn', function () {
    return this.isFirstOn ? 'pix-toggle-deprecated__off' : 'pix-toggle-deprecated__on';
  }),

  click: function (e) {
    if (e.target.className === 'pix-toggle-deprecated__off') {
      this.toggleProperty('isFirstOn');
    }

    this.onToggle(this.isFirstOn);
  },

  actions: {
    onToggle: () => {},
  },
});
