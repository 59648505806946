import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div data-test-id=\"challenge-illustration\">\n  {{#if this.displayPlaceholder}}\n    <div class=\"challenge-illustration__placeholder\" aria-label=\"{{t 'pages.challenge.illustration.placeholder'}}\">\n      <FaIcon @icon=\"image\" />\n    </div>\n  {{/if}}\n\n  <img\n    src=\"{{@src}}\"\n    alt=\"{{@alt}}\"\n    class=\"challenge-illustration__loaded-image {{this.hiddenClass}}\"\n    {{on \"load\" this.onImageLoaded}}\n  />\n\n</div>", {"contents":"<div data-test-id=\"challenge-illustration\">\n  {{#if this.displayPlaceholder}}\n    <div class=\"challenge-illustration__placeholder\" aria-label=\"{{t 'pages.challenge.illustration.placeholder'}}\">\n      <FaIcon @icon=\"image\" />\n    </div>\n  {{/if}}\n\n  <img\n    src=\"{{@src}}\"\n    alt=\"{{@alt}}\"\n    class=\"challenge-illustration__loaded-image {{this.hiddenClass}}\"\n    {{on \"load\" this.onImageLoaded}}\n  />\n\n</div>","moduleName":"mon-pix/components/challenge-illustration.hbs","parseOptions":{"srcName":"mon-pix/components/challenge-illustration.hbs"}});
import Component from '@glimmer/component';
import { trySet, action } from '@ember/object';

export default class ChallengeIllustration extends Component {
  hiddenClass = 'challenge-illustration__loaded-image--hidden';
  displayPlaceholder = true;

  @action
  onImageLoaded() {
    trySet(this, 'displayPlaceholder', false);
    trySet(this, 'hiddenClass', null);
  }
}
